import React from "react";

import getSeoData from "src/helpers/getSeoData";
import getImageObject from "src/helpers/getImageObject";

import SectionLight from "src/components/Modules/SectionLight";
import SEO from "src/components/Modules/SEO";
import PageStyles from "src/components/styles/PageStyles";
import ContentWrapper from "src/components/UI/Structure/ContentWrapper";
import HeroSlider from "src/components/Modules/HeroSlider";
import UpcomingArtists from "src/components/Home/UpcomingArtists";
import LatestNews from "src/components/Home/LatestNews";
import UpcomingConcerts from "src/components/Home/UpcomingConcerts";

const HomeTemplate = ({ page }) => {
  // Choose data based on gatsby or preview mode
  const { title, acfSeoPage, acfHomePage } = page || {};

  // Set page data
  const images =
    acfHomePage?.slide?.length > 0
      ? acfHomePage.slide.map((image) => {
          return {
            label: image.label,
            image: getImageObject(image.image),
          };
        })
      : [];

  const { seoImage, seoTitle, seoDescription } = getSeoData(acfSeoPage);
  return (
    <PageStyles>
      <SEO
        home={true}
        title={seoTitle || title}
        ogImage={
          seoImage ? seoImage : images?.length > 0 ? images[0]?.image : null
        }
        description={seoDescription || acfHomePage?.title}
      />
      <SectionLight sticky>
        {images?.length > 0 && (
          <HeroSlider
            headerColor="light"
            title={acfHomePage.title}
            slides={images}
          />
        )}
      </SectionLight>

      <ContentWrapper>
        <UpcomingArtists label="Aktuella artister" />
        <SectionLight>
          <LatestNews label="Utvalda nyheter" />
        </SectionLight>
        <UpcomingConcerts label="Kommande konserter" />
      </ContentWrapper>
    </PageStyles>
  );
};

export default HomeTemplate;
