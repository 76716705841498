import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { differenceInCalendarDays } from "date-fns";
import shuffle from "lodash/shuffle";
import getImageObject from "src/helpers/getImageObject";
import Media from "src/components/Media";
import Flex from "src/components/UI/Structure/Flex";
import Box from "src/components/UI/Structure/Box";
import Label from "src/components/UI/Typography/Label";
import Artists from "src/components/Modules/Artists/Artists";
import Button from "src/components/UI/Button";

const UpcomingArtists = ({ data, label }) => {
  // TODO: Add preview data
  const { edges: events } = data.allWpEvent;
  const events3months = [];
  const events1month = [];
  const events2weeks = [];
  const allArtists = [];
  const now = Date.now();

  // Get the best matching events in the future
  events.forEach((item) => {
    const daysBetween = differenceInCalendarDays(
      new Date(item.node.acfEventPage.date),
      now
    );
    if (daysBetween < 90 && daysBetween > -1) {
      if (events3months[item.node.acfEventPage.artist.title]) {
        events3months[item.node.acfEventPage.artist.title]++;
      } else {
        events3months[item.node.acfEventPage.artist.title] = 1;
      }
    }
    if (daysBetween < 30 && daysBetween > -1) {
      if (events1month[item.node.acfEventPage.artist.title]) {
        events1month[item.node.acfEventPage.artist.title]++;
      } else {
        events1month[item.node.acfEventPage.artist.title] = 1;
      }
    }
    if (daysBetween < 14 && daysBetween > -1) {
      if (events2weeks[item.node.acfEventPage.artist.title]) {
        events2weeks[item.node.acfEventPage.artist.title]++;
      } else {
        events2weeks[item.node.acfEventPage.artist.title] = 1;
      }
    }
    if (daysBetween > -1) {
      if (!allArtists.includes(item.node.acfEventPage.artist.title)) {
        allArtists.push(item.node.acfEventPage.artist.title);
      }
    }
  });

  // Sort artists by event count
  const eventKeys3months = Object.keys(events3months);
  const upcomingArtists3months = eventKeys3months
    .sort((a, b) => {
      return events3months[b] - events3months[a];
    })
    .slice(0, 2);

  const eventKeys1month = Object.keys(events1month);
  const upcomingArtists1month = eventKeys1month
    .sort((a, b) => {
      return events1month[b] - events1month[a];
    })
    .filter((item) => {
      return !upcomingArtists3months.includes(item);
    })
    .slice(0, 2);

  const eventKeys2weeks = Object.keys(events2weeks);
  const upcomingArtists2weeks = eventKeys2weeks
    .sort((a, b) => {
      return events2weeks[b] - events2weeks[a];
    })
    .filter(
      (item) =>
        !upcomingArtists3months.includes(item) &&
        !upcomingArtists1month.includes(item)
    );

  const upcomingArtists2weeksRandom = shuffle(upcomingArtists2weeks).slice(
    0,
    5 - (upcomingArtists3months.length + upcomingArtists1month.length)
  );

  const upcomingArtistsTotal = upcomingArtists3months.concat(
    upcomingArtists1month,
    upcomingArtists2weeksRandom
  );

  const upcomingArtist =
    upcomingArtists2weeksRandom.length === 0
      ? allArtists
          .filter((artist) => !upcomingArtistsTotal.includes(artist))
          .slice(0, 1)
      : [];

  const upcomingArtists = upcomingArtistsTotal.concat(upcomingArtist).reverse();

  const artists = upcomingArtists.map((item) => {
    const artist = data.allWpArtist.edges
      .filter(({ node }) => {
        return node.title === item && !node.acfArtistPage.hidden;
      })
      .map(({ node: artist }) => {
        return {
          id: artist.id,
          title: artist.title,
          link: artist.link,
          image: getImageObject(artist.featuredImage),
          country: artist.acfArtistPage.country,
        };
      });
    return artist[0];
  });

  return (
    <Flex
      py={[5, null, null, 5]}
      justifyContent="space-between"
      alignItems="center"
      flexDirection="column"
      width={1}
    >
      {label && <Label>{label}</Label>}
      <Box pt={[5, null, null, 5]} pb={[5, null, null, 0]} width={12 / 12}>
        <Artists
          overlay
          artists={artists}
          readMore
          readMoreText={"Visa alla artister"}
          carousel
        />
      </Box>
      <Media
        query="(max-width: 991px)"
        render={() => (
          <Box width={1} px={4}>
            <Button
              label="Visa alla artister"
              width={1}
              size="large"
              link="/artister"
            >
              Visa alla artister
            </Button>
          </Box>
        )}
      />
    </Flex>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        allWpEvent(sort: { fields: [acfEventPage___date], order: ASC }) {
          edges {
            node {
              title
              acfEventPage {
                date
                artist {
                  ... on WpArtist {
                    title
                    databaseId
                  }
                }
              }
            }
          }
        }
        allWpArtist {
          edges {
            node {
              id
              title
              link
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                        placeholder: DOMINANT_COLOR
                        width: 1920
                      )
                    }
                  }
                }
              }
              acfArtistPage {
                country
                hidden
              }
            }
          }
        }
      }
    `}
    render={(data) => <UpcomingArtists data={data} {...props} />}
  />
);
