import React from "react";
import Posts from "src/components/Modules/Posts";
import { StaticQuery, graphql } from "gatsby";
import { format } from "date-fns";
import svLocale from "date-fns/locale/sv";
import getImageObject from "src/helpers/getImageObject";

const LatestNews = ({ data, label }) => {
  // TODO: Add preview data
  const wordpressPosts = data.allWpPost.edges.map(({ node: event }) => event);
  const postItems = wordpressPosts.slice(0, 3).map((post) => {
    return {
      title: post.title,
      label: format(new Date(post.date), "dd MMM yyyy", { locale: svLocale }),
      slug: post.link,
      image: getImageObject(post.featuredImage),
    };
  });
  return (
    <Posts
      label={label}
      link={"/nyheter"}
      linkText="Visa alla nyheter"
      posts={postItems}
    />
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        allWpPost(sort: { fields: [date], order: DESC }) {
          edges {
            node {
              id
              date
              title
              link
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                        placeholder: DOMINANT_COLOR
                        width: 1920
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <LatestNews data={data} {...props} />}
  />
);
