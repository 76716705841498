import getImageObject from "src/helpers/getImageObject";

const getSeoData = (acf) => {
  if (!acf) return {};
  const seoImage = getImageObject(acf.seoImage);
  const seoTitle = acf.seoTitle;
  const seoDescription = acf.seoDescription;

  return {
    seoImage,
    seoTitle,
    seoDescription,
  };
};

export default getSeoData;
