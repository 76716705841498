import React, { Component } from "react";

import Box from "src/components/UI/Structure/Box";
import Text from "src/components/UI/Text";
import FadeCarousel from "src/components/Modules/Carousel/FadeCarousel";
import HeroStyles from "src/components/styles/HeroStyles";
import Media from "src/components/Media";
import Image from "src/components/UI/Image";

class HeroSlider extends Component {
  componentDidMount = () => {
    if (this.props.headerColor) {
      if (this.props.headerColor === "dark") {
        document.body.classList.add("header-dark");
      } else {
        document.body.classList.remove("header-dark");
      }
    }
  };

  render() {
    const { title, slides, headerColor, ...rest } = this.props;

    return (
      <HeroStyles
        alignItems={["flex-end", null, "center"]}
        py={[4, null, 10]}
        px={[4]}
        images={slides}
        size="medium"
        {...rest}
      >
        <Box
          width={[12 / 12, null, 7 / 12]}
          ml={[0, null, (1 / 12) * 100 + "%"]}
          zIndex={3}
        >
          <Text
            lineHeight="1.33em"
            as="h1"
            color="white"
            fontSize={["30px", null, "4vw", "3.5vw"]}
            my="0"
          >
            {title}
          </Text>
        </Box>
        <FadeCarousel>
          {slides &&
            slides.map((slide, index) => (
              <div className="hero-image" key={index}>
                <Image
                  className="image"
                  image={slide.image ? slide.image : null}
                />

                <Media
                  query="(min-width: 768px)"
                  render={() => (
                    <Box color="white" className="label">
                      {slide.label}
                    </Box>
                  )}
                />
              </div>
            ))}
        </FadeCarousel>
      </HeroStyles>
    );
  }
}

export default HeroSlider;
