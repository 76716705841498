import React from "react";
import Box from "src/components/UI/Structure/Box";
import styled from "styled-components";
import Swiper from "react-id-swiper";
import "swiper/swiper-bundle.css";

const Wrapper = styled(Box)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .swiper-container {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  width: 100%;
  .swiper-slide {
    transition-duration: 1s !important;
    z-index: 1;
    opacity: 0 !important;
  }
  .swiper-slide-active {
    opacity: 1 !important;
    z-index: 2;
  }
`;

const defaultParams = {
  slidesPerView: 1,
  effect: "fade",
  autoplay: {
    delay: 9000,
  },
};

const FadeCarousel = ({ children, params, className }) => (
  <Wrapper className={className}>
    <Swiper {...defaultParams} {...params}>
      {children}
    </Swiper>
  </Wrapper>
);

export default FadeCarousel;
