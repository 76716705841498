import React from "react";

import Flex from "src/components/UI/Structure/Flex";
import Text from "src/components/UI/Text";
import Box from "src/components/UI/Structure/Box";
import Label from "src/components/UI/Typography/Label";
import styled from "styled-components";
import Media from "src/components/Media";
import Link from "src/components/UI/Link";
import Button from "src/components/UI/Button";
import Carousel from "src/components/Modules/Carousel/Carousel";
import Image from "src/components/UI/Image";

import url from "url";

const ImageWrapper = styled(Box)`
  &:after {
    content: "";
    width: 100;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  > div {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
  }
  > img {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Item = styled(Flex)`
  &:before {
    content: "";
    display: block;
    width: 0;
    padding-top: ${(props) => props.theme.dimensions.aspectRatio.portrait};
  }
`;

const Title = styled(Text)`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledLink = styled(Link)`
  width: 100%;
  display: block;
  color: white;
  text-decoration: none;

  .Post__Image {
    ${(props) => props.theme.mixins.transition([{ property: "transform" }])};
    transform: scale(1);
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .Post__Title,
  .Post__Date {
    ${(props) =>
      props.theme.mixins.transition([
        { property: "transform" },
        { property: "opacity" },
      ])};
    opacity: 1;
  }

  &:hover {
    .Post__Image {
      transform: scale(1.03);
    }
    .Post__Title,
    .Post__Date {
      opacity: 0;
    }
    .Post__Title {
      transform: translateY(-5%) translateX(-50%);
    }
    .Post__Date {
      transform: translateY(-140%);
    }
  }
`;

const Date = styled(Text)`
  position: absolute;
  bottom: 0px;
  transform: translateY(-150%);
`;

const Posts = ({ posts, label, link, linkText }) => (
  <Flex
    bg="dark"
    py={[5, null, null, 5]}
    justifyContent="space-between"
    alignItems="center"
    flexDirection="column"
    width={1}
  >
    {label && <Label color="light">{label}</Label>}
    <Flex
      flexDirection={["column", null, null, "row"]}
      py={[5, null, null, 0]}
      width={12 / 12}
    >
      <Media
        query="(min-width: 992px)"
        render={() =>
          posts.map((post, index) => (
            <Item
              key={index}
              position="relative"
              textAlign="center"
              py={[12, null, null, 12, 18]}
              px={["5vw", null, null, "8vw", "8vw"]}
              color="white"
              width={1 / 3}
            >
              <StyledLink to={post?.slug ? url.parse(post.slug).pathname : ""}>
                <Flex
                  height="100%"
                  width={1}
                  position="relative"
                  alignItems="center"
                  flexDirection="column"
                >
                  <ImageWrapper className="Post__Image">
                    <StyledImage
                      image={post.image ? post.image : null}
                      alt={post.title}
                    />
                  </ImageWrapper>
                  <Title
                    className="Post__Title"
                    lineHeight="1.5em"
                    fontSize="2.3vw"
                    m="auto"
                    width={["130%", null, null, "150%"]}
                    fontWeight="500"
                  >
                    <span dangerouslySetInnerHTML={{ __html: post.title }} />
                  </Title>
                  <Date
                    className="Post__Date"
                    fontSize="1vw"
                    style={{ textTransform: "uppercase", zIndex: 1 }}
                    fontWeight="500"
                  >
                    {post.label}
                  </Date>
                </Flex>
              </StyledLink>
            </Item>
          ))
        }
      />
      {posts && (
        <Media
          query="(max-width: 991px)"
          render={() => (
            <Carousel
              className="light"
              params={{
                spaceBetween: 64,
              }}
            >
              {posts.map((post, index) => (
                <Item
                  key={index}
                  position="relative"
                  textAlign="left"
                  justifyContent="center"
                  color="white"
                  width={1}
                >
                  <Flex
                    height="100%"
                    position="absolute"
                    alignItems="flex-start"
                    flexDirection="column"
                    width={1}
                  >
                    <ImageWrapper>
                      <StyledImage
                        image={post.image ? post.image : null}
                        alt={post.title}
                      />
                    </ImageWrapper>
                    <Box
                      className="animated-content"
                      width={1}
                      pl={3}
                      lineHeight="1.25em"
                      fontSize={["6vw", "30px"]}
                      m="auto"
                    >
                      <Text
                        as="p"
                        lineHeight="1.25em"
                        fontSize={["6vw", "30px"]}
                        width="110%"
                        fontWeight="500"
                      >
                        {post.title}
                      </Text>
                    </Box>
                    <Text
                      pl={3}
                      position="relative"
                      mt="auto"
                      mb={4}
                      fontSize={2}
                      style={{ textTransform: "uppercase", zIndex: 1 }}
                      fontWeight="500"
                    >
                      {post.label}
                    </Text>
                  </Flex>
                </Item>
              ))}
            </Carousel>
          )}
        />
      )}
    </Flex>
    {link && linkText && (
      <Media
        query="(max-width: 991px)"
        render={() => (
          <Flex px={4} justifyContent="center" width={1}>
            <Button
              label={linkText}
              size="large"
              border="white"
              width={1}
              link={link}
            >
              <Box width={1} textAlign="center">
                {linkText}
              </Box>
            </Button>
          </Flex>
        )}
      />
    )}
    {link && linkText && (
      <Media
        query="(min-width: 992px)"
        render={() => (
          <Flex px={4} justifyContent="center">
            <Button
              label={linkText}
              size="large"
              border="white"
              width={"auto"}
              link={link}
            >
              <Box width={1} textAlign="center">
                {linkText}
              </Box>
            </Button>
          </Flex>
        )}
      />
    )}
  </Flex>
);

export default Posts;
