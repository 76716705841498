import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import { format, addHours } from "date-fns";
import svLocale from "date-fns/locale/sv";
import { differenceInCalendarDays } from "date-fns";

import Flex from "src/components/UI/Structure/Flex";
import Box from "src/components/UI/Structure/Box";
import Text from "src/components/UI/Text";
import Button from "src/components/UI/Button";
import Label from "src/components/UI/Typography/Label";
import Media from "src/components/Media";
import Carousel from "src/components/Modules/Carousel/Carousel";
import BuyButton from "src/components/Modules/BuyButton";

const Item = styled(Flex)`
  &:before {
    content: "";
    display: block;
    width: 0;
    padding-top: 125%;
    ${media.md`
      padding-top:82%;
    `}
  }
`;

const UpcomingConcerts = ({ data, label }) => {
  const now = Date.now();
  const wordpressEvents = data.allWpEvent.edges
    .map(({ node: event }) => event)
    .filter((event) => {
      const daysBetween = differenceInCalendarDays(
        addHours(new Date(event.acfEventPage.date), 22),
        now
      );
      return daysBetween > 0;
    });
  const upcomingEvents = wordpressEvents.slice(0, 8 - wordpressEvents.length);
  const today = Date.now();
  return (
    <Flex
      py={[5, null, null, 5]}
      alignItems="center"
      flexDirection="column"
      width={1}
    >
      {label && <Label>{label}</Label>}
      <Box py={[5, null, null, 5]} width={12 / 12}>
        <Media
          query="(min-width: 991px)"
          render={() => (
            <Flex width={1} px={3} flexWrap="wrap">
              {upcomingEvents.map(({ title, link, ...rest }, index) => {
                const { artist, date, location, ticket } = rest.acfEventPage;
                return (
                  <Item width={[null, null, null, 3 / 12]} key={index} px={1}>
                    <Flex
                      py={[2]}
                      mb={2}
                      bg="gray.default"
                      flexDirection="column"
                      width={1}
                      alignItems="center"
                    >
                      <Box>
                        <Text
                          style={{ textTransform: "uppercase" }}
                          fontSize={["1.25vw", null, null, "1vw"]}
                        >
                          {format(new Date(date), "dd MMM yyyy", {
                            locale: svLocale,
                          })}
                        </Text>
                      </Box>
                      <Flex
                        fontSize={["1.6vw", null, null, "1.25vw"]}
                        lineHeight={"1.8em"}
                        alignItems="center"
                        textAlign="center"
                        flexDirection="column"
                        my="auto"
                      >
                        <Text fontWeight="500">{artist.title}</Text>
                        <Text>{location.venue}</Text>
                        <Text>
                          {location.city}, {location.country}
                        </Text>
                      </Flex>
                      <Box>
                        <BuyButton
                          status={ticket.status}
                          ticketUrl={ticket.link}
                          ticketReleaseDate={ticket.releaseDate}
                          artistUrl={artist.url}
                        />
                      </Box>
                    </Flex>
                  </Item>
                );
              })}
            </Flex>
          )}
        />
        <Media
          query="(max-width: 990px)"
          render={() => (
            <Carousel>
              {upcomingEvents.map(({ title, link, ...rest }, index) => {
                const { artist, date, location, ticket } = rest.acfEventPage;
                return (
                  <Item width={1 / 3} key={index}>
                    <Flex
                      py={[4]}
                      px={4}
                      bg="gray.default"
                      flexDirection="column"
                      width={1}
                      alignItems="center"
                    >
                      <Box>
                        <Text
                          style={{ textTransform: "uppercase" }}
                          fontSize={2}
                        >
                          {format(new Date(date), "dd MMM yyyy", {
                            locale: svLocale,
                          })}
                        </Text>
                      </Box>
                      <Flex
                        fontSize={4}
                        lineHeight={"1.8em"}
                        alignItems="center"
                        textAlign="center"
                        flexDirection="column"
                        my="auto"
                      >
                        <Text fontWeight="500">{artist.title}</Text>
                        <Text>{location.venue}</Text>
                        <Text>
                          {location.city}, {location.country}
                        </Text>
                      </Flex>
                      <Box width={1}>
                        <BuyButton
                          status={ticket.status}
                          ticketUrl={ticket.link}
                          ticketReleaseDate={ticket.releaseDate}
                          artistUrl={artist.url}
                        />
                      </Box>
                    </Flex>
                  </Item>
                );
              })}
            </Carousel>
          )}
        />
      </Box>
      <Box px={4} width={[1, null, "auto"]}>
        <Button
          label="Visa alla konserter"
          width={1}
          size="large"
          link="/konserter"
        >
          Visa alla konserter
        </Button>
      </Box>
    </Flex>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        allWpEvent(sort: { fields: [acfEventPage___date], order: ASC }) {
          edges {
            node {
              title
              link
              acfEventPage {
                location {
                  venue
                  city
                  country
                }
                date
                artist {
                  ... on WpArtist {
                    title
                    databaseId
                  }
                }
                ticket {
                  status
                  releaseDate
                  link
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <UpcomingConcerts data={data} {...props} />}
  />
);
